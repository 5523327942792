<template>
  <div class="page-content">
    <page-breadcrumb title="Banner list" class="mb-2" />

    <data-table-ssr id="item_list" ref="item_list"
      :create-row-fn="createItem" :create_schema="create_schema" :item_default_data="{
        items: { type: 'translatable-components', translations: [], content: [] }
      }"
      :get-list-fn="getList" :columns="item_fields"
      :update_schema="update_schema" :edit-row-fn="updateItem"
      :delete-row-fn="deleteItem"
    />
  </div>
</template>

<script>
import { removeEmpty } from '@core/utils/index'
import service from '../service'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Market', field: 'market', input_type: 'text', validate: { required: true }, },
      { label: 'Items', field: 'items', input_type: 'ui-component' },
    ]
  }
];
const update_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Items', field: 'items', input_type: 'ui-component' },
    ]
  }
];;

const item_fields = [
  { label: 'Market', field: 'market' },
];

export default {
  data(){
    return{
      create_schema, update_schema,
      item_fields,
      total: 0,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
      // this.$refs.item_list.getList();
    },
    async toEditPage({ _id }) {
      this.$router.push({ name: 'ai_banner-detail', params: { id: _id } });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
  }
}
</script>
